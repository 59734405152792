<template>
  <div class="liquefy-page">
    <Wave class="wave"/>
    <div class="progress-container">
      <div style="display: flex; align-items: center; justify-content:center">
        <div class="odometer">{{ percentage }}</div>
        <span class="span-fade-in" style="font-size: 24px;">%</span>
      </div>
      <div class="waveform"></div>
      <SoundWave md class="soundWave" />
      <p class="status">LIQUEFYING ...</p>
    </div>

    <footer class="footer-text">
      To upload music, let's collaborate responsibly. Ensure all licenses are in
      order before applying. Join our creative community and apply today to
      start transforming sound into scents.
    </footer>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import SoundWave from '../components/SoundWaves.vue';
import Wave from '../components/Wave.vue';
import { useAudioStore } from '@/store/audioStore';
import { startAnalysis } from '@/utils/AudioProcessing';

const audioStore = useAudioStore();
startAnalysis(audioStore.getAudioData);
import Odometer from 'odometer/odometer.js';
import 'odometer/themes/odometer-theme-default.css';

onMounted(() => {
  const odometer = new Odometer({
    el: document.querySelector('.odometer'),
  });

  setTimeout(() => {
    odometer.update(99)
  }, 600)
  setTimeout(() => odometer.update(100), 2750);
  setTimeout(() => {
    router.push('/scent-breakdown');
  }, 5000);
});
const router = useRouter();
const percentage = ref(0);
</script>

<style scoped>
* {
  font-family: OutfitLight, sans-serif;
  color: #06001c;
}

.odometer {
  opacity: 1 !important;
  font-size: 24px;
  margin-left: -1px;
  font-family: OutfitLight, sans-serif;
}

.wave {
  margin-top: 0;
}

.wave > * {
  margin: 0;
}

.fly-enter-active,
.fly-leave-active {
  transition: transform 0.5s, opacity 0.5s;
}
.fly-enter {
  transform: translateY(50px);
  opacity: 0;
}
.fly-leave-to {
  transform: translateY(-50px);
  opacity: 0;
}

.liquefy-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  padding: 24px;
  box-sizing: border-box;
}

.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.percentage {
  font-size: 36px;
  color: #000;
  margin-bottom: 20px;
}

.waveform {
  margin: 20px 0;
}

.waveform img {
  width: 100px;
}

.status {
  font-size: 20px;
  letter-spacing: 0.1em;
}

.footer-text {
  font-size: 12px;
  max-width: 500px;
  margin: auto auto 50px;
}

@media (max-width: 992px) {
  .liquefy-page {
    height: auto;
  }

  .footer-text {
    margin: auto auto 10px;
  }

  .soundWave > * {
    width: 70% !important;
  }
}

@media (max-width: 576px) {
  .liquefy-page {
    height: 100%;
    padding: 12px;
  }

  .soundWave > * {
    width: 100% !important;
  }
}
</style>
