<template>
  <div class="title-color-card">
    <div class="wrapper">
      <div class="input-group">
        <input :value="bottleTitle" @input="handleTitleChange" id="bottle-title" type="text" />
      </div>
  
      <div class="color-picker-group">
        <chrome
          v-model="selectedColor"
          class="custom-chrome-picker"
        />
      </div>
      <MainButton class="purchaseBtn" @click="goToThankYou">
        <p class="mainBtnContent">
          <span class="span-fade-in">PURCHASE</span> <span class="span-fade-in">NOW</span>
        </p>
      </MainButton>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { Chrome } from '@ckpack/vue-color';
import { useRouter } from 'vue-router';
import css from '../styles/titleAndColorCard.css'
import MainButton from '../components/MainButton.vue';

const bottleTitle = ref('Name Your Fragrance');
const selectedColor = ref('#FF0000');
const router = useRouter();
const emit = defineEmits(['handleTitleChange', 'handleColorChange']);
const handleTitleChange = (e) => {
  bottleTitle.value = e.target.value;
  emit('handleTitleChange', e.target.value)
}

watch(selectedColor, (newVal) => {
  const newColor =  newVal?.hex ?? '#000';
  emit('handleColorChange', newColor)
})

function goToThankYou() {
  router.push('/thank-you');
}
</script>

<style scoped>
.title-color-card {
  border: 1px solid #f0f0f0;
  border-radius: 15px;
  padding: 20px;
  background-color: #fff;
  width: 300px;
  opacity: 0.9;
}

.input-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column
}

.wrapper {
  width: 80%;
  margin: auto;
}

.wrapper > * {
  width: 100%;
}

.purchaseBtn {
  margin: auto;
}

#bottle-title {
  width: 90%;
}

label {
  font-size: 20px;
  text-align: left;
  display: block;
  color: #06001c;
  font-weight: 600;
  margin-bottom: 10px;
}

input {
  width: 100%;
  padding: 14px 0 14px 30px;
  margin-top: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 50px;
}

.color-picker-group {
  margin-bottom: 20px;
}

.custom-chrome-picker {
  width: 100% !important;
}

.custom-chrome-picker .vc-chrome-fields,
.custom-chrome-picker .vc-chrome-saturation {
  display: none !important;
}

.custom-chrome-picker .vc-chrome-controls {
  display: flex;
  justify-content: center;
}

.purchase-btn {
  padding: 10px 30px;
  background-color: #06001C;
  color: #FFFFFF;
  border-radius: 50px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
}

@media (max-width: 576px) {
  .purchaseBtn {
    display: none;
  }
}
</style>
