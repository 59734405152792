<template>
  <section class="notes-container">
    <img
      class="info-icon"
      :src="infoIcon"
      alt="Info Icon"
      @mouseover="showInfo = true"
      @mouseleave="showInfo = false"
    />

    <article v-if="!showInfo">
      <div class="note-section">
        <p class="note-title">MUSIC ESSENCE</p>
        <p class="note-content">{{ musicNotes.join(', ') }}</p>
      </div>
      <div class="note-section">
        <p class="note-title">TOP NOTES</p>
        <p class="note-content">
          {{ topNotes.map(note => '/' + note).join(', ') }}
        </p>
      </div>
      <div class="note-section">
        <p class="note-title">MIDDLE NOTES</p>
        <p class="note-content">
          {{ middleNotes.map(note => '/' + note).join(', ') }}
        </p>
      </div>
    </article>

    <article v-else>
          <p>
            Sound frequencies vary by input and environment, so the Liquid Sound algorithm may produce unique results each time, adding to the personalized experience.
          </p>
    </article>
  </section>
</template>

<script setup>
import { ref } from 'vue';
import infoIcon from '../assets/images/info.png'; // Use the correct path for your icon

const showInfo = ref(false);

const props = defineProps({
  musicNotes: {
    type: Array,
    required: true,
  },
  topNotes: {
    type: Array,
    required: true,
  },
  middleNotes: {
    type: Array,
    required: true,
  },
});
</script>

<style scoped>
.notes-container {
  border: 1px solid #f0f0f0;
  border-radius: 20px;
  padding: 35px;
  min-height: 250px;
  max-height: 250px;
  width: 100%;
  background-color: #fff;
  text-align: left;
  opacity: 0.8;
  position: relative;
  overflow: auto;
  scrollbar-gutter: stable;
}

.note-section {
  margin-bottom: 20px;
}

.note-title {
  font-size: 18px;
  font-weight: 400;
  color: #c4c4c4;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.note-content {
  font-size: 20px;
  font-weight: 600;
  margin-top: 5px;
  color: #000;
  letter-spacing: 0.05em;
  font-family: OutfitLight;
}

/* Info Icon Styles */
.info-container {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.info-icon {
  position: absolute;
  right: 0;
  top: 0;
  width: 24px;
  height: 24px;
  transform: translate(-8px, 24px);
}

/* Info Box Styles */
.info-box {
  position: absolute;
  top: 30px;
  right: 0;
  width: 250px;
  background: #fff;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  font-size: 14px;
  color: #333;
  z-index: 10;
  overflow-y: auto;
  max-height: 150px;
  text-align: left;
}

@media (max-width: 992px) {
  .notes-container {
    text-align: center;
    min-height: 200px;
    max-height: 200px;
  }
}
</style>
