<template>
    <div class="btlSingle" @click="goToPayment">
       <video playsinline autoplay muted loop>
        <source :src="bottle?.movie || butla" type="video/mp4" />
       </video>
      <p>{{ bottle.name }}</p>
    </div>
  </template>
  
  <script setup>
  import butla from '../assets/movies/bottle.mp4';
  import { useRouter } from 'vue-router';
  import { useBottleStore } from '../store/bottleStore';
  
  const { bottle } = defineProps(['bottle']);
  const router = useRouter();
  const bottleStore = useBottleStore();
  
  function goToPayment() {
    bottleStore.setBottleData(bottle);
    router.push('/payment');
  }
  </script>
  
  <style scoped>
  .btlSingle {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    height: auto;
    width: 150px;
  }
  
  video {
    width: 100%;
    height: auto;
  }

  .btlSingle img {
    width: 150px;
    height: auto;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 20px;
    font-weight: 600;
    color: #06001C;
    text-transform: uppercase;
  }

  @media (max-width: 992px) {
    .btlSingle {
    margin-bottom: 56px;
  }
  }
  </style>
  