<template>
  <button
    class="outlined-button"
    @mouseleave="active = false"
    :class="{ active: active }"
  >
    <slot />
  </button>
</template>

<script setup>
import { ref } from 'vue';
const active = ref(false);
</script>

<style scoped>
* {
  font-family: OutfitRegular;
}

.outlined-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 22px 36px;
  background-color: transparent;
  color: #06001c;
  border-radius: 50px;
  border: 2px solid #06001c;
  font-size: 18px;
  cursor: pointer;
  overflow: hidden;
  width: auto;
  height: 48px;
  transition: color 0.3s ease, transform 0.3s ease;
  animation: resize 1s;
  text-transform: uppercase;
  text-wrap: nowrap;
}

.outlined-button::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  background-color: #06001c;
  border-radius: 50%;
  transition: width 0.5s ease, height 0.5s ease, top 0.5s ease, left 0.5s ease;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.outlined-button:hover::before {
  width: 300%;
  height: 300%;
  top: 50%;
  left: 50%;
}

.outlined-button:hover {
  color: #fff;
}

.outlined-button.active {
  transform: scale(1.1);
  height: 72px;
}

@keyframes resize {
  0% {
    scale: 1.2;
  }
  100% {
    scale: 1;
  }
}
</style>
