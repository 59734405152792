import { createRouter, createWebHistory } from 'vue-router';
import LandingPage from '@/views/LandingPage.vue';
import SongAnalyzer from '@/views/SongAnalyzer.vue';
import Playlist from '@/views/Playlist.vue';
import Payment from '@/views/Payment.vue';
import AudioProcessing from '@/views/AudioProcessing.vue';
import BreakdownScent from '@/views/BreakdownScent.vue';
import CustomizeBottle from '@/views/CustomizeBottle.vue';
import ThankYou from '@/views/ThankYou.vue';
import StartingPage from '@/components/StartingPage.vue';
import Behind from '@/views/Behind.vue';

const routes = [
  { path: '/main', component: LandingPage },
  { path: '/analyze', component: SongAnalyzer },
  { path: '/playlist', component: Playlist },
  { path: '/payment', component: Payment },
  { path: '/liqufy', component: AudioProcessing },
  { path: '/scent-breakdown', component: BreakdownScent },
  { path: '/customize', component: CustomizeBottle },
  { path: '/thank-you', component: ThankYou },
  { path: '/', component: StartingPage },
  { path: '/behind', component: Behind },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;