<template>
  <div class="breakdown-page">
    <video playsinline autoplay muted loop id="bgVideo">
      <source :src="videoSrc" type="video/mp4" />
      Your browser does not support HTML5 video.
    </video>
    <div style="display: flex">
      <header class="breakdown-header">
        <GoBack :goBack="goBack" />
      </header>
      <Wave />
    </div>

    <h1 class="title">BREAKDOWN OF SCENTS</h1>

    <section class="breakdown-content">
      <div class="notes-section">
        <NotesCard
          :musicNotes="musicNotes"
          :topNotes="topNotes"
          :middleNotes="middleNotes"
        />
      </div>

      <div class="bottle-image">
        <video playsinline class="rotatingBottle" autoplay muted loop>
          <source :src="butla" type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
      </div>
      <div class="customizeBtnWrapper">
        <MainButton class="customizeBtn" @click="goToCustomize">
          <div class="mainBtnContent">
            <span class="span-fade-in">CUSTOMIZE</span> <span class="span-fade-in"> BOTTLE</span>
          </div>
        </MainButton>
      </div>
    </section>
  </div>
</template>

<script setup>
import NotesCard from '../components/NotesCard.vue';
import MainButton from '../components/MainButton.vue';
import butla from '../assets/movies/bottleAnimated.mp4';
import Wave from '../components/Wave.vue';
import videoSrc from '../assets/movies/waterBG.mp4';
import GoBack from '@/components/Navigator.vue';

import { useRouter } from 'vue-router';

const defaultData = {  name: 'Atchoo!',
  topNotes: ['Chestnut', 'Green Mandarin'],
  middleNotes: ['Hazelnut', 'Chocolate'],
  musicNotes: ['D4', 'F4', 'C5'],
  price: 130,
  edition: '30/50',
  size: '50mm/1.7 fl oz',
  quantity: '01'} 

const {
  name,
  topNotes,
  middleNotes,
  musicNotes,
  price,
  edition,
  size,
  quantity,
} = window.bottle ?? defaultData;

const router = useRouter();
function goToCustomize() {
  router.push('/customize');
}

function goBack() {
  router.push('/analyze');
}
</script>

<style scoped>
.breakdown-page {
  text-align: center;
  padding: 24px;
  max-width: 70%;
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 95%;
}

.notes-section > * {
  max-width: 300px;
  width: calc(100% - 35px);
}

#bgVideo {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  min-height: 100vh;
  transform: translate(-50%, -50%);
  z-index: -1;
  object-fit: cover;
  opacity: 0.3 !important;
}

.rotatingBottle {
  width: 100%;
  mix-blend-mode: multiply;
  animation: fade-out 2s;
}

.customizeBtn {
  text-wrap: nowrap;
  margin: auto;
}

.breakdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header-icon {
  font-size: 24px;
}

.title {
  font-size: 3.1vmax;
  color: #1d1d1f;
  margin: auto auto 0 auto;
  text-wrap: nowrap;
}

.breakdown-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto 0;
}

.breakdown-content > * {
  flex: 1;
}

.bottle-image img {
  width: 300px;
  height: auto;
}

.customize-button {
  width: 30%;
  display: flex;
  justify-content: center;
}

.btn-customize {
  padding: 10px 20px;
  background-color: #06001c;
  color: #ffffff;
  border-radius: 50px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  letter-spacing: 0.05em;
}

@keyframes fade-out {
  0% {
    opacity: 0;
    transform: translateY(10%);
    mix-blend-mode: multiply;
  }
  10% {
    opacity: 0.05;
  }
  20% {
    opacity: 0.05;
  }
  30% {
    opacity: 0.1;
  }
  40% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    mix-blend-mode: multiply;
  }
}

@keyframes show-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 1700px) {
  .rotatingBottle {
    width: 80%
  }
}

@media (max-width: 1500px) {
  .title {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .rotatingBottle {
    width: 60%
  }
}

@media (max-width: 1200px) {
  .title {
    font-size: 4.3vmax;
  }

  .breakdown-content {
    flex-direction: column;
  }

  .notes-section > * {
    max-width: 300px;
    width: 80%;
    margin: 20px 0;
  }

  .breakdown-page {
    max-width: 100%;
  }

  .bottle-image {
    margin: 20px 0;
    order: -1;
  }
}

@media (max-width: 992px) {
  .title {
    font-size: 1.6vmax;
  }

  .notes-section > * {
    width: 75%;
  }

  .customizeBtnWrapper {
    margin-bottom: 20px;
  }
}
</style>
