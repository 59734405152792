<template>
    <div class="record-section">
            <RecordBtn ref="recordBtn" class="record-btn" @recording-stopped="handleRecordingStop" @recording-reset="handleRecordingReset" @recording-started="handleRecordingStarted"/>
            <p style="cursor: pointer; margin: 0 0 40px" v-if="isStopped" @click="resetRecording">MAKE NEW RECORD</p>
            <p style="cursor: pointer;" v-if="!isStopped && !isRecording" @click="startRecording">CLICK TO RECORD</p>
            <MainButton v-if="isStopped" @click="createFragrance">
                <p class="mainBtnContent">
                    <span class="span-fade-in">CREATE</span> <span class="span-fade-in">FRAGRANCE</span>
                </p>
            </MainButton>
        </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import MainButton from './MainButton.vue';
import RecordBtn from './RecordBtn.vue';
import { ref } from 'vue';
const recordBtn = ref(null);
const isStopped = ref(false);
const isRecording = ref(false);
const router = useRouter();
import { useAudioStore } from '@/store/audioStore';
const audioStore = useAudioStore();

const resetRecording = () => {
    recordBtn?.value?.resetRecording?.();
    isRecording.value = false;
}

const startRecording = () => {
    isRecording.value = true;
    recordBtn?.value?.startRecording?.();
}

const stopRecording = () => {
    recordBtn?.value?.stopRecording?.();
}

const handleRecordingStarted = () => {
    isRecording.value = true;
}


const handleRecordingReset = () => {
    isStopped.value = false;
}

const createFragrance = () => {
    const audioData = audioStore?.getAudioData;
    if(!audioData?.size) return;

    router.push('/liqufy');
};

const handleRecordingStop = () => {
    isStopped.value = true;
}

defineExpose({ stopRecording });
</script>

<style scoped>
.record-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  user-select: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.record-section * {

    -webkit-tap-highlight-color: transparent;
  }

.record-btn {
    margin-bottom: 30px;
}

</style>