<template>
  <div class="landing">
    <div class="nav">
      <!-- <GoBack
        class="navigator"
        :goBack="goToBehind"
        :alternativeText="'ABOUT'"
        :arrowHidden="true"
      /> -->
    </div>
    <div class="videoWrapper"></div>
    <div class="contentWrapper">
      <div class="waveWrapper title">
          <Wave class="wave fade-in-late" />
      </div>

      <SoundWave class="soundWave" lg />
      <div class="btnGroup">
        <MainButton class="navBtn" @click="navigateToLanding">
          <p class="mainBtnContent">
            <span class="span-fade-in">Create</span>
            <span class="span-fade-in">&nbsp;Fragrance</span>
          </p>
        </MainButton>

        <MainButtonOutline class="navBtn" @click="displayPlaylist">
          <span class="mainBtnOutlineContent span-fade-in">store</span>
        </MainButtonOutline>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import MainButton from '../components/MainButton.vue';
import MainButtonOutline from '../components/MainButtonOutline.vue';
import Wave from '../components/Wave.vue';
import SoundWave from '../components/SoundWaves.vue';
import GoBack from '@/components/Navigator.vue';

const router = useRouter();

function navigateToLanding() {
  router.push('/analyze');
}

function displayPlaylist() {
  router.push('/playlist');
}

function goToBehind() {
  router.push('/behind');
}
</script>

<style scoped>
.nav {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 12px;
  justify-content: flex-end;
  width: 100%;
}

.record-header {
  flex-shrink: 0;
}

.waveWrapper {
  height: 150px;
}

.wave {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.wave > * {
  margin: 0;
  max-width: 1000px;
}

.fade-in-late {
  display: inline-block;
  opacity: 0;
  filter: blur(4px);
}

.fade-in-late:nth-child(1) {
  animation: fade-in 0.8s 1.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.fade-in-late:nth-child(2) {
  animation: fade-in 0.8s 1.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.landing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 24px;
}

.title {
  font-size: 6vmax;
  margin: auto auto 0 auto;
  font-weight: 100;
}

.soundWave {
  margin: auto;
}

.navBtn {
  animation: sound-fade-in 1.5s;
}

@keyframes sound-fade-in {
  0% {
    opacity: 0;
    mix-blend-mode: multiply;
  }
  35% {
    opacity: 0;
    mix-blend-mode: multiply;
    scale: 1.3;
  }
  100% {
    opacity: 1;
    mix-blend-mode: multiply;
    scale: 1;
  }
}

.soundWave > * {
  opacity: 1 !important;
  animation: sound-fade-in 0.1s !important;
}

.wave > * {
  margin-right: 0px;
}

.btnGroup {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto auto auto;
}

.videoWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}

video {
  width: 100%;
  height: auto;
}

.contentWrapper {
  position: relative;
  z-index: 1;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media (max-width: 992px) {
  .title {
    font-size: 4vmax;
    font-weight: 992;
  }

  .soundWave > * {
    width: 100% !important;
  }

  .landing {
    padding: 0;
  }

  .nav > .navigator {
    padding-right: 24px;
  }

  .waveWrapper {
    height: 150px;
  }
  
  .wave > * {
    margin: 0;
    max-width: 300px;
  }

  .soundWave {
    margin: 7% auto 30%;
}
}
</style>
