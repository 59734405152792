<template>
    <div id="playlist">
      <div style="display: flex; width: 100%; margin-bottom: auto;">
        <header class="playlist-header">
          <GoBack :goBack="goBack"/>
        </header>
        <Wave class="wave" />
      </div>

      <h1 class="title">PLAYLIST</h1>
  
      <div class="bottleListWrapper">
        <BottleList :bottles="bottles" />
      </div>
  
      <SoundWave lg class="soundWave" />
    </div>
  </template>
  
  <script setup>
  import BottleList from '../components/BottleList.vue';
  import Wave from '../components/Wave.vue';
  import SoundWave from '../components/SoundWaves.vue';
  import GoBack from '@/components/Navigator.vue';
  import shibuya from '../assets/songs/shib.mp3';
  import jung from '../assets/songs/jung.mp3';
  import breeze from '../assets/songs/breeze.mp3';
  import shibMov from '../assets/movies/shibMov.mp4';
  import jungMov from '../assets/movies/jungMov.mp4';
  import breezeMov from '../assets/movies/breezeMov.mp4';
  import { useRouter } from 'vue-router'

  const bottles = [
    { name: 'Concrete Jungle', topNotes: ['Birch Tar', 'Black Pepper'], middleNotes: ['Labdanum', 'Patchouli '], musicNotes: ['F5', 'C#4', 'D#6', 'D8'], price: 130, edition: '30/50', size: '50mm/1.7 fl oz', quantity: '01', song: jung, movie:  jungMov },
    { name: 'Shibuya Crossing', topNotes: ['Pink Pepper', 'Saffron'], middleNotes: ['Amber', 'Vanilla'], musicNotes: ['B4', 'E4', 'A#6', 'C#7'], price: 130, edition: '30/50', size: '50mm/1.7 fl oz', quantity: '01', song: shibuya, movie: shibMov  },
    { name: 'Namibian Breeze', topNotes: ['Rose', 'Ylang-Ylang'], middleNotes: ['Green Tea', 'Cypress'], musicNotes: ['C#2', 'G#2', 'F3', 'C4'], price: 130, edition: '30/50', size: '50mm/1.7 fl oz', quantity: '01', song: breeze, movie: breezeMov}
  ];
  const router = useRouter()

  function goBack() {
    router.push('/main')
  }

  </script>
  
  <style scoped>
  #playlist {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-width: 70%;
    margin: auto;
    padding: 24px;
  }
  
  h1 {
    margin: 0 0 auto 0;
  }

  .playlist-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .wave * {
    margin-bottom: -10px;
  }
  
  .header-icon {
    font-size: 24px;
  }
  
  h1 {
    font-size: 48px;
    font-weight: 600;
    letter-spacing: 0.05em;
    color: #1D1D1F;
    margin-bottom: 40px;
  }
  
  .bottleListWrapper {
    display: flex;
    justify-content: center;
    margin: auto auto 0 auto;
    width: 100%;
  }

  .soundWave {
    margin: 0 0 auto 0;
    opacity: 0.1 !important;
  }

  @media (max-width: 1200px) {
    #playlist {
      height: auto;
    }

    .title {
      margin: 60px 0 60px;
    }
  }

  @media (max-width: 576px) {
    .soundWave {
      position: fixed;
      bottom: 0;
      width: 120%;
      height: 150px;
      mix-blend-mode: multiply;
    } 

  }
  </style>
  