import { hertzToNoteMap } from '../assets/hertzToNoteMap';
import { noteToSmellMap } from '../assets/noteToSmellMap';

let analysisInterval;
let intervalSmellMap = {};
let totalOccurrences = 0;

const amountOfNotes = 4;

// Frequency range constants for human voice
const VOICE_MIN_FREQUENCY = 40;
const VOICE_MAX_FREQUENCY = 9999;

// Adjusted sampling interval for voice analysis
const ANALYSIS_INTERVAL_MS = 20;

// Apply noise reduction using threshold
const NOISE_THRESHOLD = 140;

export function startAnalysis(audioBlob) {
    if (!audioBlob) {
        alert('No audio data available for analysis.');
        return;
    }

    const audioContext = new window.AudioContext();
    const reader = new FileReader();

    reader.onload = function (e) {
        audioContext.decodeAudioData(e.target.result, function (buffer) {
            analyzeAudio(buffer, audioContext.sampleRate);
        });
    };

    reader.readAsArrayBuffer(audioBlob);
}

function analyzeAudio(audioBuffer, sampleRate) {
    const chunkSizeInSeconds = 3;
    const audioDuration = audioBuffer.duration;
    let currentTime = 0;

    let chunksProcessed = 0; // Counter to track how many chunks have been fully processed

    const totalChunks = Math.ceil(audioDuration / chunkSizeInSeconds);

    const audioContext = new window.AudioContext();

    function handleFinalization() {
        Object.keys(intervalSmellMap).forEach((key) => {
            if (intervalSmellMap[key]) {
                intervalSmellMap[key].percentage = intervalSmellMap[key]?.occurrences / totalOccurrences;
            }
        });
        console.log(intervalSmellMap);
        clearInterval(analysisInterval);
        const notes = getMostPrevalentNotes(amountOfNotes);
        const smells = getMostPrevalentSmells();
        const { topSmells, middleSmells } = getSmellsHalfed(smells);
        const bottle = {
            name: 'Shibuya Crossing',
            topNotes: topSmells,
            middleNotes: middleSmells,
            musicNotes: notes,
            price: 130,
            edition: '30/50',
            size: '50mm/1.7 fl oz',
            quantity: '01'
        };

        window.bottle = bottle;
        intervalSmellMap = {};
        totalOccurrences = 0;
    }

    while (currentTime < audioDuration) {
        const startOffset = currentTime;
        const endOffset = Math.min(currentTime + chunkSizeInSeconds, audioDuration);

        const source = audioContext.createBufferSource();
        const analyser = audioContext.createAnalyser();
        analyser.fftSize = 2048;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        source.buffer = audioBuffer;
        source.connect(analyser);

        source.start(0, startOffset, endOffset - startOffset);

        // Handle analysis interval
        analysisInterval = setInterval(() => {
            analyser.getByteFrequencyData(dataArray);
            const hertz = getDominantFrequency(dataArray, sampleRate);

            if (hertz < VOICE_MIN_FREQUENCY || hertz > VOICE_MAX_FREQUENCY) return;
            if (Math.max(...dataArray) < NOISE_THRESHOLD) return;

            const note = convertHertzToNote(hertz);
            const smell = convertNoteToSmell(note);

            if (!intervalSmellMap.hasOwnProperty(note)) {
                intervalSmellMap[note] = { occurrences: 0, smells: [], note, percentage: 0 };
            }

            intervalSmellMap[note].occurrences += 1;
            totalOccurrences += 1;
            intervalSmellMap[note].percentage = intervalSmellMap[note].occurrences / totalOccurrences;
            intervalSmellMap[note].smells.push(smell);
        }, ANALYSIS_INTERVAL_MS);

        source.onended = function () {
            clearInterval(analysisInterval);
            chunksProcessed += 1;

            if (chunksProcessed === totalChunks) {
                handleFinalization();
            }
        };

        currentTime += chunkSizeInSeconds;
    }
}

function getDominantFrequency(dataArray, sampleRate) {
    const peakBin = dataArray.indexOf(Math.max(...dataArray));
    const hertz = (peakBin / dataArray.length) * (sampleRate / 2);
    return hertz;
}

function convertHertzToNote(hertz) {
    let closestNote = null;
    let minDifference = Infinity;

    for (const freq in hertzToNoteMap) {
        const frequency = parseFloat(freq);
        const difference = Math.abs(hertz - frequency);

        if (difference < minDifference) {
            minDifference = difference;
            closestNote = hertzToNoteMap[freq];
        }
    }

    return closestNote;
}

function convertNoteToSmell(note) {
    return noteToSmellMap[note] || 'Unknown';
}

function getMostPrevalentNotes(amount) {
    const noteArray = Object.keys(intervalSmellMap).map((key) => intervalSmellMap[key]);
    noteArray.sort((a, b) => b.occurrences - a.occurrences);
    const topNotes = noteArray.slice(0, amount).map(noteData => noteData.note);
    return topNotes;
}

function getMostPrevalentSmells() {
    const topNotes = getMostPrevalentNotes(amountOfNotes);
    const topSmells = [];
    for (const note of topNotes) {
        topSmells.push(convertNoteToSmell(note));
    }
    return topSmells;
}

function getSmellsHalfed(smells) {
    const smellsCopy = [...smells];
    const topSmells = smellsCopy.splice(0, Math.floor(smells.length / 2));
    const middleSmells = smellsCopy;
    return { topSmells, middleSmells };
}
