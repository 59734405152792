export const noteToSmellMap = {
    'C0': 'Lime',
    'C#0': 'Lemon',
    'D0': 'Bergamot',
    'D#0': 'Grapefruit',
    'E0': 'Mandarin',
    'F0': 'Orange Blossom',
    'F#0': 'Green Apple',
    'G0': 'Mint',
    'G#0': 'Neroli',
    'A0': 'Lemongrass',
    'A#0': 'Pineapple',
    'B0': 'Basil',
    'C1': 'Aloe',
    'C#1': 'Peach',
    'D1': 'Mango',
    'D#1': 'Blackcurrant',
    'E1': 'Fig',
    'F1': 'Melon',
    'F#1': 'Apricot',
    'G1': 'Papaya',
    'G#1': 'Kiwi',
    'A1': 'Raspberry',
    'A#1': 'Cranberry',
    'B1': 'Grape',
    'C2': 'Strawberry',
    'C#2': 'Rose',
    'D2': 'Jasmine',
    'D#2': 'Lavender',
    'E2': 'Orange Blossom',
    'F2': 'Violet',
    'F#2': 'Marigold',
    'G2': 'Clary Sage',
    'G#2': 'Ylang-Ylang',
    'A2': 'Gardenia',
    'A#2': 'Carnation',
    'B2': 'Geranium',
    'C3': 'Peony',
    'C#3': 'Thyme',
    'D3': 'Rosemary',
    'D#3': 'Sage',
    'E3': 'Ivy',
    'F3': 'Green Tea',
    'F#3': 'Tomato Leaf',
    'G3': 'Eucalyptus',
    'G#3': 'Cucumber',
    'A3': 'Peppermint',
    'A#3': 'Tea Tree',
    'B3': 'Pine Needle',
    'C4': 'Cypress',
    'C#4': 'Black Pepper',
    'D4': 'Clove',
    'D#4': 'Cinnamon',
    'E4': 'Saffron',
    'F4': 'Ginger',
    'F#4': 'Cardamom',
    'G4': 'Nutmeg',
    'G#4': 'Allspice',
    'A4': 'Star Anise',
    'A#4': 'Coriander',
    'B4': 'Pink Pepper',
    'C5': 'Tarragon',
    'C#5': 'Cedarwood',
    'D5': 'Sandalwood',
    'D#5': 'Oud',
    'E5': 'Hinoki',
    'F5': 'Birch Tar',
    'F#5': 'Mahogany',
    'G5': 'Palo Santo',
    'G#5': 'Ebony',
    'A5': 'Redwood',
    'A#5': 'Maple',
    'B5': 'Teakwood',

    'C6': 'Sequoia',
    'C#6': 'Frankincense',
    'D6': 'Myrrh',
    'D#6': 'Labdanum',
    'E6': 'Opopanax',
    'F6': 'Benzoin',
    'F#6': 'Peru Balsam',
    'G6': 'Styrax',
    'G#6': 'Tolu Balsam',
    'A6': 'Amber',
    'A#6': 'Amber',
    'B6': 'Galbanum',

    'C7': 'Copal',
    'C#7': 'Vanilla',
    'D7': 'Honey',
    'D#7': 'Caramel',
    'E7': 'Cacao',
    'F7': 'Toffee',
    'F#7': 'Coconut',
    'G7': 'Maple Syrup',
    'G#7': 'Marzipan',
    'A7': 'Molasses',
    'A#7': 'Praline',
    'B7': 'Brown Sugar',

    'C8': 'Coffee',
    'C#8': 'Vetiver',
    'D8': 'Patchouli',
    'D#8': 'Oakmoss',
    'E8': 'Musk',
    'F8': 'Tobacco',
    'F#8': 'Hay',
    'G8': 'Moss',
    'G#8': 'Earth',
    'A8': 'Leather',
    'A#8': 'Coal',
    'B8': 'Peat',

    'C9': 'Animalic',
    'C#9': 'Orris Root',
    'D9': 'Benzoin Resin',
    'D#9': 'Birch Smoke',
    'E9': 'Seaweed',
    'F9': 'Salted Caramel',
    'F#9': 'Cocoa Butter',
    'G9': 'Smoked Wood',
    'G#9': 'Fir Balsam',
    'A9': 'Whiskey',
    'A#9': 'Bourbon',
    'B9': 'Shiso Leaf'
};
