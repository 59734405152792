import { defineStore } from 'pinia';

export const useBottleStore = defineStore('bottle', {
    state: () => ({
        bottleData: null,  // The state where we'll store the bottle object
    }),
    actions: {
        setBottleData(bottle) {
            this.bottleData = bottle;  // Action to update the bottle data
        }
    },
    getters: {
        getBottleData: (state) => state.bottleData  // Getter to access the bottle data
    }
});
