<template>
  <button
    class="solid-button"
    @mouseleave="active = false"
    :class="{ active: active }"
  >
    <slot class="content" />
  </button>
</template>

<script setup>
import { ref } from 'vue';
const active = ref(false);

const  { width, xsm, sm, md, lg, xlg } = defineProps(['width', 'xsm', 'sm', 'md', 'lg', 'xlg'])

const getStyles = () => {
    if(width) {
        return width;
    } else if(xsm !== undefined) {
        return '15%'
    } else if(sm !== undefined) {
        return '30%'
    } else if(md !== undefined) {
        return '50%'
    } else if(lg !== undefined) {
        return '70%'
    } else if(xlg !== undefined) {
        return '90%'
    } else {
        return '100%'
    }
}

</script>

<style scoped>
* {
  font-family: OutfitLight;
}

.solid-button {
  position: relative;
  padding: 25px 36px;
  background-color: #06001C;
  color: #FFFFFF;
  border-radius: 50px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  overflow: hidden;
  width: auto;
  height: 48px;
  transition: color 0.3s ease, transform 0.3s ease;
  z-index: 999;
  animation: resize 1.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  text-wrap: nowrap;
}

.content {
  position: absolute;
  z-index: 2;
}

.solid-button::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  background: #292929;
  border-radius: 50%;
  transition: width 0.5s ease, height 0.5s ease, top 0.5s ease, left 0.5s ease;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.solid-button:hover::before {
  width: 300%;
  height: 300%;
  top: 50%;
  left: 50%;
}

.solid-button:hover {
  color: #fff;
}

.solid-button.active {
  transform: scale(1.1);
  height: 72px;
}

@keyframes resize {
  0% {
    scale: 1.2;
  }
  100% {
    scale: 1;
  }
}
</style>
