<template>
    <div>
        <img :src="Wave" :style="`width: ${getStyles()}`" alt="">
    </div>    
</template>

<script setup>
import Wave from '../assets/images/soundWave.gif'
const  { width, xsm, sm, md, lg, xlg } = defineProps(['width', 'xsm', 'sm', 'md', 'lg', 'xlg'])

const getStyles = () => {
    if(width) {
        return width;
    } else if(xsm !== undefined) {
        return '15%'
    } else if(sm !== undefined) {
        return '30%'
    } else if(md !== undefined) {
        return '50%'
    } else if(lg !== undefined) {
        return '70%'
    } else if(xlg !== undefined) {
        return '90%'
    } else {
        return '100%'
    }
}
</script>

<style scoped>
    div {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        animation: sound-fade-in 1s;
    }
    
    img {
        mix-blend-mode: multiply;
        min-width: 45px;
    }

    @keyframes sound-fade-in {
        0% {
          opacity: 0;
          mix-blend-mode: multiply;
        }
        35% {
            opacity: 0;
            mix-blend-mode: multiply;
          }
        100% {
          opacity: 1;
          mix-blend-mode: multiply;
        }
      }
</style>