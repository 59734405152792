import { defineStore } from 'pinia';

export const useAudioStore = defineStore('audio', {
    state: () => ({
        audioData: null,  // The state where we'll store the bottle object
    }),
    actions: {
        setAudioData(audio) {
            this.audioData = audio;  // Action to update the bottle data
        }
    },
    getters: {
        getAudioData: (state) => state.audioData  // Getter to access the bottle data
    }
});
