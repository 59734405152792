<template>
  <div class="product-page">
    <video playsinline autoplay muted loop id="bgVideo">
      <source :src="videoSrc" type="video/mp4" />
      Your browser does not support HTML5 video.
    </video>

    <div style="display: flex; width: 100%; margin-bottom: auto">
      <header class="product-header">
        <GoBack @click="stopSound" :goBack="goBack"/>
      </header>
      <Wave />
    </div>
    <h1 class="product-title"><span class="span-fade-in">{{ name }}</span></h1>

    <section class="product-content">
      <div class="product-info">
        <NoteDisplay
          :musicNotes="musicNotes"
          :topNotes="topNotes"
          :middleNotes="middleNotes"
          class="noteDisplay"
        />
        <PlaySoundBtn @click="playSound" class="desktop" />
      </div>

      <div class="product-image">
        <video playsinline autoplay muted loop>
          <source :src="movie || butla" type="video/mp4" />
        </video>
      </div>

      <div class="product-checkout">
        <DetailsDisplay class="detailsDisplay" :edition="edition" :quantity="quantity" :size="size" />
        <PaymentBtn class="desktop"  :price="price"/>
      </div>
      <PlaySoundBtn @click="playSound" class="mobile" />
      <PaymentBtn class="mobile"  :price="price"/>

    </section>

  </div>
</template>

<script setup>
import { useBottleStore } from '../store/bottleStore'; // Import the store
import { useRouter } from 'vue-router';
import NoteDisplay from '../components/NotesCard.vue';
import DetailsDisplay from '../components/DetailsCard.vue';
import butla from '../assets/movies/bottle.mp4';
import Wave from '../components/Wave.vue';
import videoSrc from '../assets/movies/waterBG.mp4';
import PlaySoundBtn from '@/components/PlaySoundBtn.vue';
import PaymentBtn from '@/components/PaymentBtn.vue';
import GoBack from '@/components/Navigator.vue';
import jung from '../assets/songs/jung.mp3';

const bottleStore = useBottleStore();

const defaultData = {  name: 'Concrete Jungle!',
  topNotes: ['Chestnut', 'Green Mandarin'],
  middleNotes: ['Hazelnut', 'Chocolate'],
  musicNotes: ['D4', 'F4', 'C5'],
  price: 130,
  edition: '30/50',
  size: '50mm/1.7 fl oz',
  quantity: '01',
  song: jung
} 

const {
  name,
  topNotes,
  middleNotes,
  musicNotes,
  price,
  edition,
  size,
  quantity,
  song,
  movie
} = bottleStore.getBottleData ?? defaultData;
const router = useRouter();
const audio = new Audio(song ?? atchoo);

function playSound() {
  audio.play();
}

function stopSound() {
  audio.pause();
}

function goBack() {
  router.push('/playlist');
}
</script>

<style scoped>
.product-page {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 95%;
  max-width: 70%;
  margin: auto;
  padding: 24px;
}

.noteDisplay, .detailsDisplay {
  animation: show-content 2s;
}

.payText {
  letter-spacing: 3px;
}

.soundBtn {
  margin-top: 20px;
  display: flex;
  text-wrap: nowrap;
  max-width: 50%;
}

.paymentBtn {
  margin-top: 20px;
  width: 100%;
}

.soundWave {
  display: inline;
  margin: 3px 0 0 0;
  width: 50%;
  animation: show-in 10s;
  opacity: 0;
}

.soundWave > img{
  animation: show-in 10s;
}

#bgVideo {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  min-height: 100vh;
  transform: translate(-50%, -50%);
  z-index: -1;
  object-fit: cover;
  opacity: 0.4 !important;
}

.product-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header-icon {
  font-size: 24px;
}

.product-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5%;
  width: 100%;
  margin: 0 auto auto auto;
  flex-wrap: wrap;
}

.product-content > * {
  flex: 1;
}

.product-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  width: 100%;
}

.product-image video {
  height: auto;
  mix-blend-mode: multiply;
  animation: fade-out 2s;
  width: 70%;
  margin-bottom: 80px;
  min-width: 150px;
}

.product-checkout {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  width: 100%;
}

@keyframes res {
  0% {
    scale: 200%;
  }
  100% {
    scale: 100%;
  }
}

.play-sound-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding: 12px 20px;
  border: 2px solid black;
  border-radius: 50px;
  background-color: transparent;
  color: #000;
  cursor: pointer;
  font-size: 16px;
  letter-spacing: 0.05em;
  width: 100%;
  animation: res 1;
}
.play-sound-btn > span {
  font-family: OutfitLight, sans-serif;

}
.sound-icon {
  margin-right: 10px;
}

.checkout-btn {
  margin-top: 20px;
  padding: 10px 30px;
  background-color: #06001c;
  color: #ffffff;
  border-radius: 50px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  letter-spacing: 0.05em;
}

.product-title {
  font-weight: 600;
  letter-spacing: 0.05em;
  color: #1d1d1f;
  margin: auto;
  font-size: 64px;
  margin-bottom: 5%;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.bottle-0 {
  animation: fade-out 1s;
}

.mobile {
  display: none;
}

@keyframes fade-out {
  0% {
    opacity: 0;
    transform: translateY(15%);
    mix-blend-mode: multiply;
  }
  10% {
    opacity: 0.05;
  }
  20% {
    opacity: 0.05;
  }
  30% {
    opacity: 0.1;
  }
  40% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
    mix-blend-mode: multiply;
  }
}

@keyframes show-in {
  0% {
    opacity: 0 !important;
    mix-blend-mode: multiply;
  }
  50% {
    opacity: 0 !important;
    mix-blend-mode: multiply;
  }
  100% {
    opacity: 1;
    mix-blend-mode: multiply;
  }
}


@keyframes show-content {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}

@media (max-width: 1200px) {
  .product-page {
    height: auto;
  }

  .product-title {
    margin: 60px 0;
  }
}

@media (max-width: 992px) {
  .product-image {
    order: -1;
  }

  .product-checkout, .product-info {
    margin: 0 0 20px
  }

  .product-title {
    font-size: 36px;
  }

  .product-content {
    flex-direction: column;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: flex;
    max-width: 350px;
  }

  .soundWave {
    width: 10%;
  }
}
</style>
