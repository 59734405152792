<template>
  <div class="record-button" :class="{ recording: isRecording, stopped: isStopped, active: isRecording }"
    @click="toggleRecording" @touchstart="onTouchStart" @touchend="onTouchEnd" @mouseenter="hover = true"
    @mouseleave="hover = false">
    <div class="circle-container" v-show="!isRecording && !isFinished">
      <div class="outer-circle" :class="{ 'hover': hover }">
        <div v-if="!isRecording" class="inner-circle"></div>
      </div>

      <div class="circle red red-circle-deactivated"></div>
      <div class="circle-wrapper">
        <svg width="180" height="180" viewBox="0 0 180 180" class="circle-svg">
          <circle cx="90" cy="90" r="89" class="dashed-circle"></circle>
        </svg>
      </div>
    </div>

    <div v-show="isRecording || isFinished" class="circle-container">
      <div class="outer-circle-active"></div>
      <div v-show="!isFinished" class="outer-circle-active outer-circle-one"></div>
      <div v-show="!isFinished" class="outer-circle-active outer-circle-two"></div>
      <div v-show="!isFinished" class="outer-circle-active outer-circle-three"></div>

      <div v-show="isRecording || isFinished" class="circle red red-circle-activated" :class="{check: isFinished}">
        <svg width="80" height="80" viewBox="0 0 80 80" :class="{'circular-progress': !isFinished}">
          <circle class="bg"></circle>
          <circle class="fg"></circle>
        </svg>
        <img class="checkIcon" v-if="isFinished" :src="checkIcon" alt="">
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useAudioStore } from '../store/audioStore';
import checkIcon from '../assets/images/check.png'

const audioStore = useAudioStore();
const isRecording = ref(false);
const isFinished = ref(false);
const audioUrl = ref(null);
const isStopped = ref(false);
const hover = ref(false);
let mediaRecorder;
let chunks = [];
let autoStopTimeout;
let minRecordingTimeout;
let longPressTimeout = null;
let isLongPress = false;
let timePassed = false;

const emit = defineEmits(['recording-stopped', 'recording-reset', 'recording-started']);

const recordingStopped = () => {
  emit('recording-stopped');
};

const recordingReset = () => {
  emit('recording-reset');
};

const recordingStarted = () => {
  emit('recording-started');
};



const onTouchStart = () => {
  startRecording();
};

const onTouchEnd = () => {
console.log('onTouchEnd')
  clearTimeout(longPressTimeout);
  if (isRecording.value && !isLongPress) {
    stopRecording();
  }
};

const startRecording = () => {
  if (isStopped.value || isRecording.value) return;
  chunks = [];
  timePassed = false;
  setTimeout(() => {
        timePassed = true;
      }, 3000);

  navigator.mediaDevices
    .getUserMedia({ audio: true })
    .then((stream) => {
      mediaRecorder = new MediaRecorder(stream);
      mediaRecorder.ondataavailable = (event) => {
        chunks.push(event.data);
      };

      mediaRecorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'audio/ogg; codecs=opus' });
        audioUrl.value = window.URL.createObjectURL(blob);
        audioStore.setAudioData(blob);
        chunks = [];
        isFinished.value = true;
      };

      mediaRecorder.start();
      isRecording.value = true;
      emit('recording-started');

      autoStopTimeout = setTimeout(stopRecording, 30000);
    })
    .catch((err) => {
      console.error('Error accessing microphone:', err);
    });
};

const displayCheckIcon = () => {
  
}

const stopRecording = () => {
  if (!isRecording.value) return;
  if (!timePassed) {
    return;
  }

  if (mediaRecorder && mediaRecorder.state !== 'inactive') {
    mediaRecorder.stop();
    isRecording.value = false;
    isStopped.value = true;
    let stream = mediaRecorder?.stream;
    stream?.getTracks().forEach((track) => track?.stop());
    recordingStopped();

    if (autoStopTimeout) {
      clearTimeout(autoStopTimeout);
      autoStopTimeout = null;
    }
    if (minRecordingTimeout) {
      clearTimeout(minRecordingTimeout);
      minRecordingTimeout = null;
    }

    displayCheckIcon()
  }
};

const resetRecording = () => {
  isFinished.value = false;
  isRecording.value = false;
  isStopped.value = false;
  audioUrl.value = null;
  emit('recording-reset');
};

const toggleRecording = () => {
  if (isStopped.value) return;

  if (isRecording.value) {
    stopRecording();
  } else {
    startRecording();
  }
};

defineExpose({ resetRecording, startRecording, stopRecording });
</script>


<style scoped>
.record-button {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 190px;
  height: 190px;
  cursor: pointer;
  margin: auto;
  user-select: none;
  outline: none;
}

.circle-container {
  position: relative;
  z-index: 2;
  width: 150px;
  height: 150px;
}

.circle-wrapper {
  position: absolute;
  top: 51.5%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circle-svg {
  animation: rotate infinite 10s linear;
}

.dashed-circle {
  fill: none;
  stroke: #ccc;
  stroke-width: 2;
  stroke-dasharray: 10 10;
}

.outer-circle {
  position: relative;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease-in-out;
  animation: pulse-inner 4s linear infinite;

}

.outer-circle-active {
  position: absolute;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  background-color: #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  z-index: 4;
  transform: translate(-50%, -50%);
  animation: pulse-inner-one-minor 2.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
}

.outer-circle-active.outer-circle-one {
  position: absolute;
  height: 120%;
  width: 120%;
  opacity: 1;
  background-color: #f9f9f9;
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);
  border: #e5e5e5 1px solid;
  /* animation: pulse-small 2s infinite cubic-bezier(0.19, 1, 0.22, 1), show 2s infinite; */
    animation: pulse-small 2.5s infinite cubic-bezier(0.25, 0.46, 0.45, 0.94), show-first 2.5s infinite;
}

.outer-circle-active.outer-circle-two {
  position: absolute;
  height: 140%;
  width: 140%;
  opacity: 1;
  background-color: #f9f9f9;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  border: #e5e5e5 1px solid;
  /* animation: pulse-medium 2s infinite cubic-bezier(0.19, 1, 0.22, 1), show 2s infinite; */
    animation: pulse-medium 2.5s infinite cubic-bezier(0.25, 0.46, 0.45, 0.94), show-middle 2.5s infinite;
}

.outer-circle-active.outer-circle-three {
  position: absolute;
  height: 160%;
  width: 160%;
  opacity: 1;
  background-color: #f9f9f9;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  border: #e5e5e5 1px solid;
  /* animation: pulse-big 2s infinite cubic-bezier(0.19, 1, 0.22, 1), show 2s infinite; */
    animation: pulse-big 2.5s infinite cubic-bezier(0.25, 0.46, 0.45, 0.94), show-last 2.5s infinite;
}

.inner-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 35%;
  height: 35%;
  background-color: #0e0e10;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.red {
  background-color: #ff3b30;
}

.red-circle-deactivated {
  width: 20px !important;
  height: 20px !important;
  scale: 1 !important;
  opacity: 0.5 !important;
}

.red-circle-activated {
  width: 80px !important;
  height: 80px !important;
  scale: 1 !important;
  background-color: #a41907;
  z-index: 5;
  overflow: hidden;
}

.red-circle-activated::before {
  position: absolute;
  width: 80%;
  height: 80%;
  content: '';
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.red-circle-activated::after {
  position: absolute;
  width: 80%;
  height: 80%;
  content: '';
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.inner-circle:hover {
  opacity: 1 !important;
}

.inner-circle:hover::before {
  opacity: 1 !important;
}

.inner-circle::before:hover {
  opacity: 1 !important;
}

.wave-container {
  position: absolute;
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: show-in 1s;
}

.wave {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 220px;
  height: 220px;
  border-radius: 50%;
  border: 1px solid red;
  transform: translate(-50%, -50%);
  animation: wave 1.5s infinite;
}

.wave:nth-child(2) {
  width: 240px;
  height: 240px;
  animation-delay: 0.33s;
}

.wave:nth-child(3) {
  width: 260px;
  height: 260px;
  animation-delay: 0.66s;
}

.wave:nth-child(3) {
  width: 260px;
  height: 260px;
  animation-delay: 1s;
}

  .check {
    width: 200px;
    height: 200px;
    text-align: center; 
    color: #ddd;
    position: relative;
    overflow: hidden;
    background: #a41907;
  
  }
  
  .check::before {
    content: '';
    position: absolute;
    width: 400px;
    height: 400px;
    background: #0e0e10;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 40%;
    animation: fill 1s ease-in-out;
    animation-fill-mode: forwards
  }

  .checkIcon {
    position: absolute;
    z-index: 100;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(to right, black -1000%, black 10%, white 11%, white 105%);
    background-size: 1000%;
    -webkit-background-clip: text;
    color: transparent;
    animation: reveal 0.90s forwards;
  }

  .icon {
    font-size: 50px;

  }
  
  @keyframes reveal {
    0% {
      opacity: 0;
      filter: blur(4px);
    }
    50% {
      opacity: 0;
      filter: blur(4px);
    }
    100% {
      filter: blur(0);
      opacity: 100%;
    }
  }

  @keyframes fill {
    0% {
      top: 100px;
      transform: translatex(-50%) rotate(45deg);
    }
    100% {
      top:-50px;
      transform: translatex(-50%) rotate(0);
    }
  }


  

@keyframes wave {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0.5);
  }

  40% {
    opacity: .8;
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.3);
  }
}


@keyframes show-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse-inner-one {
  0% {
    transform:translate(-50%, -50%) scale(0.5);
    opacity: 0;
  }

  50% {
    transform:translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  
  99.9% {
    transform:translate(-50%, -50%) scale(0.5);
    opacity: 0;
  }
}

@keyframes show-last {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  66% {
    opacity: 0.33;
  }
  
  99.9% {
    opacity: 0;
  }
}

@keyframes show-middle {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  66% {
    opacity: 0.66;
  }
  
  99.9% {
    opacity: 0;
  }
}

@keyframes show-first {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  66% {
    opacity: 1;
  }

  80% {
    opacity: 0.7;
  }

  99.9% {
    opacity: 0;
  }
}

@keyframes pulse-inner-one-minor {
  0% {
    transform: translate(-50%, -50%) scale(0.9);
  }

  50% {
    transform: translate(-50%, -50%) scale(1);
  }
  
  99.9% {
    transform: translate(-50%, -50%) scale(0.9);
  }
}

@keyframes pulse-big {
  0% {
    transform: translate(-50%, -50%) scale(0.7);
  }

  50% {
    transform: translate(-50%, -50%) scale(1);
  }
  
  99.9% {
    transform: translate(-50%, -50%) scale(0.7);
  }
}

@keyframes pulse-medium {
  0% {
    transform: translate(-50%, -50%) scale(0.8);
  }

  50% {
    transform: translate(-50%, -50%) scale(1);
  }
  
  99.9% {
    transform: translate(-50%, -50%) scale(0.8);
  }
}

@keyframes pulse-small {
  0% {
    transform: translate(-50%, -50%) scale(0.9);
  }

  50% {
    transform: translate(-50%, -50%) scale(1);
  }
  
  99.9% {
    transform: translate(-50%, -50%) scale(0.9);
  }
}

@keyframes pulse-inner {
  0% {
    scale: 1;
  }

  20% {
    scale: 0.9;
  }

  22% {
    scale: 0.895;
  }

  24% {
    scale: 0.89;
  }

  26% {
    scale: 0.885;
  }

  28% {
    scale: 0.88;
  }

  30% {
    scale: 0.875;
  }

  32% {
    scale: 0.87;
  }

  33.3% {
    scale: 0.865;
  }

  35% {
    scale: 0.87;
  }

  37% {
    scale: 0.875;
  }

  39% {
    scale: 0.88;
  }

  41% {
    scale: 0.885;
  }

  43% {
    scale: 0.89;
  }

  45% {
    scale: 0.895;
  }

  47% {
    scale: 0.9;
  }

  66.6% {
    scale: 1;
  }

  99.9% {
    scale: 1;
  }
}

.circular-progress {
  position: absolute;
  width: 80%;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  --size: 80px;
  --half-size: calc(var(--size) / 2);
  --stroke-width: 8px;
  --radius: calc((var(--size) - var(--stroke-width)) / 2);
  --circumference: calc(var(--radius) * pi * 2);
  --dash: calc((var(--progress) * var(--circumference)) / 100);
  animation: progress-animation 30s linear 0s 1 forwards;
}

.circular-progress circle {
  cx: var(--half-size);
  cy: var(--half-size);
  r: var(--radius);
  stroke-width: var(--stroke-width);
  fill: transparent;
  stroke: transparent;
  stroke-linecap: round;
}

.circular-progress circle.bg {
  stroke: transparent;
}

.circular-progress circle.fg {
  transform: rotate(-90deg);
  transform-origin: var(--half-size) var(--half-size);
  stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
  transition: stroke-dasharray 0.3s linear 0s;
  stroke: #fff;
}

@property --progress {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}

@keyframes progress-animation {
  from {
    --progress: 0;
  }
  to {
    --progress: 100;
  }
}

.checkmark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.3rem;
  color: #fff;
  z-index: 3;
}

.inner-square {
  width: 18px;
  height: 18px;
  background-color: #ff3b30;
  opacity: 1 !important;
  border-radius: 4px;
}

.stopped .inner-circle::before {
  background-color: transparent;
}
</style>