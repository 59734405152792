<template>
  <div class="record-page">
    <video playsinline muted loop id="bgVideo">
      <source :src="videoSrc" type="video/mp4" />
      Your browser does not support HTML5 video.
    </video>
    <div class="nav">
      <header class="record-header">
        <GoBack :goBack="goToMainPage" />
      </header>
      <Wave class="wave"/>
      <header class="record-header">
        <!-- <GoBack :goBack="goToBehind" :alternativeText="'ABOUT'" :arrowHidden="true" /> -->
      </header>
    </div>

    <h1 class="title">
      <span class="span-fade-in">CREATE</span> <span class="span-fade-in">YOUR</span> <span class="span-fade-in">FRAGRANCE</span>
    </h1>
    <Record ref="record"/>
    
    <footer class="footer-text">
      To upload music, let's collaborate responsibly. Ensure all licenses are in
      order before applying. Join our creative community and apply today to
      start transforming sound into scents.
    </footer>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import videoSrc from '../assets/movies/wavesBG.mp4';
import Wave from '../components/Wave.vue';
import GoBack from '@/components/Navigator.vue';
import Record from '@/components/Record.vue';
import { ref } from 'vue';

const router = useRouter();
const record = ref(null);

const stopRecording = () => {
  record?.value?.stopRecording?.();
}

function goToMainPage() {
  router.push('/main');
  stopRecording();
}

function goToBehind() {
  router.push('/behind');
  stopRecording();
}

</script>

<style scoped>
.record-page {
  text-align: center;
  max-width: 70%;
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.nav {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  margin-top: 12px
}

.record-header {
  flex-shrink: 0;
}

.wave {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.wave > * {
  margin: 0;
}

#bgVideo {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  min-height: 100vh;
  transform: translate(-50%, -50%);
  z-index: -1;
  object-fit: cover;
  opacity: 0.1 !important;
}



.record-button {
  cursor: pointer;
}

.record-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-icon {
  font-size: 24px;
}

.title {
  font-size: 3.3vmax;
  color: #1d1d1f;
  margin-bottom: 40px;
  margin: auto;

}

.record-icon {
  font-size: 36px;
  color: #000;
}

.record-finished .record-icon {
  color: black;
}

.create-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #06001c;
  color: #fff;
  border-radius: 50px;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.footer-text {
  color: #666;
  max-width: 65%;
  margin: 40px auto 0;
  font-size: 1vmax;
  font-family: OutfitLight;
  margin: auto auto 20px auto;
}

p {
  cursor: pointer;
}

video {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

@media (max-width: 992px) {
  .footer-text {
    max-width: 100%;
    font-size: 1.5vmax;
    margin: 0 0 36px;
  }

  .record-page {
    padding: 0 24px;
    max-width: 85%;
  }

  .title {
    margin: auto auto 0;
  }
}

@media (max-width: 576px) {
  .title {
    font-size: 1.3vmax
  }
}
</style>
